import React, { useState } from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import menuIcon from "../images/icons/menu-hamburger.svg"

const StyledMenu = styled.ul`
  margin: 0;
  display: flex;
  list-style-type: none;
  align-items: center;
  justify-content: center;

  @media (max-width: 992px) {
    position: fixed;
    left: 0;
    right: 0;
    flex-direction: column;
    background-color: white;
    z-index: 1000;
    width: 100%;
  }
`

const MenuItem = styled.li`
  margin-left: 40px;
  font-size: 16px;
  font-weight: bold;

  @media (max-width: 992px) {
    margin: 24px;
  }
`

const MenuLink = ({ to, children }) => (
  <Link
    className="menu-item"
    to={to}
    style={{ color: "#888" }}
    activeStyle={{ color: "#000" }}
  >
    {children}
  </Link>
)

export default () => {
  const [showMenu, setShowMenu] = useState(false)

  const toggleMenu = () => setShowMenu(!showMenu)
  const menuClasses = ["d-lg-flex"]
  showMenu ? menuClasses.push("d-flex") : menuClasses.push("d-none")

  return (
    <div>
      <img src={menuIcon} className="d-lg-none" height="24px" onClick={toggleMenu} />
      <StyledMenu className={menuClasses.join(" ")}>
        <MenuItem>
          <MenuLink to="/">Accueil</MenuLink>
        </MenuItem>
        <MenuItem>
          <MenuLink to="/magasin">Magasin</MenuLink>
        </MenuItem>
        <MenuItem>
          <MenuLink to="/traiteur">Traiteur</MenuLink>
        </MenuItem>
        {/* <MenuItem>
          <MenuLink to="/fetes">✨ Fêtes 🎄</MenuLink>
        </MenuItem> */}
        <MenuItem>
          <MenuLink to="/vins-et-spiritueux">Vins & Spiritueux</MenuLink>
        </MenuItem>
      </StyledMenu>
    </div>
  )
}
