import { Link } from "gatsby"
import React from "react"
import Menu from "./menu"
import styled from "styled-components"

const Container = styled.div`
  padding: 30px 60px;
  display: flex;
  justify-content: space-between;
  font-size: 20px;
`

const Header = () => (
  <Container>
    <div className="container d-flex justify-content-between">
      <Link to="/" style={{ color: "#000", textDecoration: "none", fontWeight: "bold" }} activeStyle={{ color: "#000" }}>Raineri</Link>
      <Menu/>
    </div>
  </Container>
)

export default Header
